import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	HeaderSection,
	ContentSection,
	InlineMessage,
	ImportExample,
	Code,
	Header,
} from "../../../../../src/docs";
import { Card } from "../card/card";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { Ul } from "../../text/list/list";
import { Content } from "../content/content";
import { StructureExampleToggle } from "../common";
import { Grid } from "./grid";

export const Meta: DocPageMeta = {
	title: "Grid and Grid.Section",
	category: "Structure",
	notepad: "https://hackmd.io/JAGldegeSCK586TGDKh2Ow",
};

const simpleCard = (
	<Card>
		<Card.Header>Card</Card.Header>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit. Donec
				laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur efficitur justo non
				egestas.
			</Paragraph>
		</Content>
	</Card>
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Grid and Grid.Section"
			subTitle="Use these components to control the size of cards and display them in a responsive grid."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Grid} />
				<InlineMessage variant="warning">
					<Paragraph>
						Our structure components are strongly interconnected. Make sure to read the structure
						component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink>, to
						find out which components you need and how to combine them correctly.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Example</Header.H2>
				<Paragraph>
					Use the Grid.Section component to control the size of{" "}
					<GatsbyLink to="/lib/components/Structure/Card">cards</GatsbyLink>. Wrap it around one or
					more cards and use its <Code>size</Code> property to set a desired size. The Grid.Section
					component also adds space between cards and makes them responsive, by automatically
					adjusting their width on smaller viewports.
				</Paragraph>
				<Paragraph>
					The Grid component lays out cards in a grid. To use it, all you need to do is wrap it
					around Grid.Section components. In the example below, you can see both components doing
					their thing.
				</Paragraph>
				<Example fn={sizeExample} customBar={({ id }) => <StructureExampleToggle id={id} />} />
				<Header.H2>Properties</Header.H2>
				<Header.H3>Grid.Section</Header.H3>
				<Knobs
					component={Grid.Section}
					initialProps={{ children: simpleCard }}
					exampleValues={{
						children: {
							"single card": simpleCard,
							"two cards": (
								<>
									{simpleCard}
									{simpleCard}
								</>
							),
						},
					}}
					customBar={({ id }) => <StructureExampleToggle id={id} />}
				/>
				<Header.H3>Grid</Header.H3>
				<Knobs
					component={Grid}
					initialProps={{
						children: (
							<>
								<Grid.Section>{simpleCard}</Grid.Section>
								<Grid.Section size="half">{simpleCard}</Grid.Section>
								<Grid.Section size="half">{simpleCard}</Grid.Section>
							</>
						),
					}}
					exampleValues={{
						children: {
							"one full and two halves": (
								<>
									<Grid.Section>{simpleCard}</Grid.Section>
									<Grid.Section size="half">{simpleCard}</Grid.Section>
									<Grid.Section size="half">{simpleCard}</Grid.Section>
								</>
							),
							"one half and two fourths": (
								<>
									<Grid.Section size="half">{simpleCard}</Grid.Section>
									<Grid.Section size="fourth">{simpleCard}</Grid.Section>
									<Grid.Section size="fourth">{simpleCard}</Grid.Section>
								</>
							),
							"one third and one two-thirds": (
								<>
									<Grid.Section size="third">{simpleCard}</Grid.Section>
									<Grid.Section size="twothirds">{simpleCard}</Grid.Section>
								</>
							),
						},
					}}
					customBar={({ id }) => <StructureExampleToggle id={id} />}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Use the Grid.Section component to control the size of{" "}
								<GatsbyLink to="/lib/components/Structure/Card">cards</GatsbyLink>. Wrap it around
								one or more cards and use its <Code>size</Code> property to set a desired size.
							</>,
							<>
								Don't adjust the spacing between{" "}
								<GatsbyLink to="/lib/components/Structure/Card">cards</GatsbyLink> or the width of
								cards based on viewport size. The Grid.Section component takes care of this.
							</>,
							<>Only use the Grid component in combination with Grid.Section components.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								You only have a single{" "}
								<GatsbyLink to="/lib/components/Structure/Card">card</GatsbyLink> on a page. The
								card will (and should) take up the page's full width.
							</>,
							<>
								You need to control the size or layout of anything other than a{" "}
								<GatsbyLink to="/lib/components/Structure/Card">card</GatsbyLink>. Instead, use the{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> and{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">layout</GatsbyLink>{" "}
								components.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const sizeExample = () => (
	<>
		<Grid>
			<Grid.Section>
				<Card>
					<Card.Header>Full (default)</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="half">
				<Card>
					<Card.Header>Half</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="fourth">
				<Card>
					<Card.Header>Fourth A</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="fourth">
				<Card>
					<Card.Header>Fourth B</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="third">
				<Card>
					<Card.Header>Third</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="twothirds">
				<Card>
					<Card.Header>Two thirds</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
		</Grid>
	</>
);
